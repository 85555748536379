import { FatalErrorFallbackUI } from '@/components/FatalErrorFallbackUI';
import { LoginLayout } from '@/components/Layout/LoginLayout';
import { ScanCode } from '@/components/ScanCode';
import { AppStateContext } from '@/context/AppStateContext';
import { useContext } from 'react';

export default function Home() {
  const { showBlockingErrorPage } = useContext(AppStateContext);

  return (
    <>
      {showBlockingErrorPage ? (
        <FatalErrorFallbackUI />
      ) : (
        <LoginLayout>
          <ScanCode />
        </LoginLayout>
      )}
    </>
  );
}
