import styles from '@/components/ScanCode/ScanCode.module.css';
import { ResetData } from '@/components/ResetData';
import { ScanCodeInput } from '@/components/ScanCodeInput';

interface ScanCodeProps {
  className?: string;
}

export const ScanCode = ({ className }: ScanCodeProps) => (
  <div className={[className, styles.root].join(' ')}>
    <ScanCodeInput className={styles.form} />
    <ResetData />
  </div>
);
