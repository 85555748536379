import { useContext, useEffect, useState } from 'react';
import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { getErrorMessage } from '@/utils/errorMessages';
import { ErrorTypes } from '@/types/error';
import { LocalEventStorage } from '@/persistence/localeEventStorage';
import { ScanCodeErrorContext } from '@/context/ScanCodeErrorContext';
import { useLoadScanCodeEventData } from '@/hooks/useLoadScanCodeEventData';
import { useRouter } from 'next/router';
import { clientConfig } from '@/config/client.config';
import styles from './index.module.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Laser } from '@/components/Laser';
import { Message } from '@/i18n/Message';

interface ScanCodeInputProps {
  className?: string;
}

export const ScanCodeInput = ({ className }: ScanCodeInputProps) => {
  const router = useRouter();
  const { loadEventData, dataLoadingInProgress, scanCodeDataLoadFinished } = useLoadScanCodeEventData();
  const { scanCodeError, clearScanCodeError } = useContext(ScanCodeErrorContext);
  const [scanCode, setScanCode] = useState('');
  const canSubmit = scanCode.trim() !== '';
  const invalidScanCodeError = scanCodeError?.type === ErrorTypes.INVALID_SCAN_CODE;
  const noOfflineDataFoundError = scanCodeError?.type === ErrorTypes.SCAN_CODE_NO_OFFLINE_DATA_FOUND;

  const handleScanCodeInputChange = (event: { target: { value: string } }) => {
    setScanCode(event.target.value);
    clearScanCodeError();
  };

  useEffect(() => {
    setScanCode(LocalEventStorage.getItem('LAST_USED_SCAN_CODE'));
  }, []);

  useEffect(() => {
    // has to be an own useEffect, because otherwise - if we combine the initial useEffect with this one - we get the ScanCode from the localStorage or an empty inputField (eg on coping a valid code from the clipboard) as soon as we clear the error message in handleScanCodeInputChange
    if (scanCodeError === undefined) {
      return;
    }
    setScanCode(scanCodeError.scanCode);
  }, [scanCodeError]);

  useEffect(() => {
    if (!scanCodeDataLoadFinished || scanCodeError !== undefined) {
      return;
    }
    router.push(`/${encodeURIComponent(scanCode)}`);
  }, [router, scanCode, scanCodeDataLoadFinished, scanCodeError]);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    clearScanCodeError();
    loadEventData(scanCode);
  };

  return (
    <form onSubmit={handleSubmit} className={[className, styles.form].join(' ')}>
      <Typography variant="h6" gutterBottom={true}>
        <Message id="scan-code.title" />
      </Typography>
      <Laser className={styles.laser} />
      <TextField
        id="scan_code_input"
        label={<Message id="scan-code.input-label" />}
        variant="outlined"
        onChange={handleScanCodeInputChange}
        value={scanCode}
        disabled={dataLoadingInProgress}
        error={invalidScanCodeError}
        helperText={invalidScanCodeError ? getErrorMessage(ErrorTypes.INVALID_SCAN_CODE) : undefined}
        inputProps={{ maxLength: clientConfig.maxScanCodeLength, className: styles.scanCodeInput }}
        fullWidth={true}
        margin="normal"
        autoFocus={true}
        InputProps={{
          endAdornment: (() => {
            if (dataLoadingInProgress) {
              return <CircularProgress size={16} />;
            }
            if (!canSubmit) {
              return undefined;
            }
            return (
              <Button variant="text" type="submit" color="primary" size="small" endIcon={<ChevronRightIcon />}>
                <Message id="scan-code.submit-button" />
              </Button>
            );
          })(),
        }}
      />
      <Typography variant="caption" gutterBottom={true} className={styles.instructions}>
        <Message id="scan-code.instructions" />
      </Typography>
      {noOfflineDataFoundError ? (
        <Alert severity="error">
          <Message id="scan-code.no-offline-data-found" />
        </Alert>
      ) : null}
    </form>
  );
};
