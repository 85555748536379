import MuiSnackbar from '@mui/material/Snackbar';
import { Alert, AlertProps } from '@mui/material';

export const RESET_RESULT_SHOW_TIMEOUT_IN_MS = 4_000;

interface Props {
  message: string;
  open: boolean;
  onClose: () => void;
  severity?: AlertProps['severity'];
}

export const Snackbar = ({ message, open, onClose, severity = 'error' }: Props) => (
  <MuiSnackbar open={open} autoHideDuration={RESET_RESULT_SHOW_TIMEOUT_IN_MS} onClose={onClose}>
    <Alert elevation={6} variant="filled" severity={severity} onClose={onClose}>
      {message}
    </Alert>
  </MuiSnackbar>
);
