import { Footer } from '@/components/Footer';
import styles from '@/components/Layout/Layout.module.css';
import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
}

export const LoginLayout = ({ children }: LayoutProps) => {
  return (
    <div className={[styles.root, styles.rootWithoutHeader].join(' ')}>
      <main className={styles.content}>{children}</main>
      <Footer className={styles.status} />
    </div>
  );
};
