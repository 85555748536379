import { getStorageWrapper } from '@/utils/storageWrapper';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

export const useResetApp = () => {
  const [showResetError, setShowResetError] = useState<boolean>(false);
  const [resetSuccessful, setResetSuccessful] = useState<boolean>(false);

  const resetResult = useCallback(() => {
    setShowResetError(false);
    setResetSuccessful(false);
  }, []);

  const { mutate, isPending } = useMutation({
    mutationFn: resetAppData,
    networkMode: 'offlineFirst',
    onSuccess: (result) => {
      if (!result.ok) {
        setShowResetError(true);
      }
      setResetSuccessful(true);
    },
  });

  return {
    isLoading: isPending,
    resetApp: mutate,
    resetResult,
    resetSuccessful,
    showResetError,
  };
};

const resetAppData = async () => {
  const result = await getStorageWrapper().clearObjectStores();

  if (!result.ok) {
    return result;
  }

  localStorage.clear();

  return result;
};
