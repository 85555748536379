import { translate } from '@/i18n';
import { useEffect, useState } from 'react';
import styles from '@/components/ResetData/ResetData.module.css';
import { ResetConfirmationDialog } from '@/components/ResetData/ResetConfirmationDialog';
import { RESET_RESULT_SHOW_TIMEOUT_IN_MS, Snackbar } from '@/library/Snackbar';
import { countUnpushedTickets } from '@/utils/ticketCounts';
import { useResetApp } from '@/hooks/useResetApp';
import { CircularProgress } from '@mui/material';

export const ResetData = () => {
  const [unpushedTicketCount, setUnpushedTicketCount] = useState<number>(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const { isLoading, resetSuccessful, showResetError, resetResult, resetApp } = useResetApp();

  useEffect(() => {
    if (resetSuccessful) {
      setTimeout(() => {
        // reset/reload Web Worker via page reload
        window.location.reload();
      }, RESET_RESULT_SHOW_TIMEOUT_IN_MS);
    }
  }, [resetSuccessful]);

  const handleResetData = () => {
    countUnpushedTickets().then((count) => {
      if (count > 0) {
        setUnpushedTicketCount(count);
        setIsConfirmDialogOpen(true);
      } else {
        resetApp();
      }
    });
  };

  return (
    <>
      <button
        color="inherit"
        type="button"
        onClick={handleResetData}
        disabled={isLoading || resetSuccessful}
        className={styles.resetButton}
      >
        {translate('common', 'resetButtonTitle')}
        {isLoading ? <CircularProgress className={styles.spinner} size={11} /> : undefined}
      </button>
      <Snackbar
        open={showResetError || resetSuccessful}
        onClose={resetResult}
        message={getSnackBarMessage(resetSuccessful, showResetError)}
        severity={getSnackBarSeverity(resetSuccessful, showResetError)}
      />
      <ResetConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => {
          setIsConfirmDialogOpen(false);
          resetApp();
        }}
        unpushedTicketCount={unpushedTicketCount}
      />
    </>
  );
};

const getSnackBarMessage = (resetSuccessful: boolean, showResetError: boolean) => {
  if (resetSuccessful) {
    return translate('reset', 'resetSuccess');
  }
  if (showResetError) {
    return translate('reset', 'resetError');
  }
  return '';
};

const getSnackBarSeverity = (resetSuccessful: boolean, showResetError: boolean) => {
  if (resetSuccessful) {
    return 'success';
  }
  if (showResetError) {
    return 'error';
  }
  return undefined;
};
