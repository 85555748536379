import { TicketCheckinUploadStatus } from '@/enums/tickets';
import { getStorageWrapper } from '@/utils/storageWrapper';

export const countUnpushedTickets = async () => {
  const result = await getStorageWrapper().getTicketCheckinsByStatus(TicketCheckinUploadStatus.UNPUSHED);
  if (!result.ok) {
    console.warn('error while fetching ticket count :: ', result.error);
    return 0;
  }
  return result.data.length;
};
