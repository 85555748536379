import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { translate } from '@/i18n';
import styles from '@/components/ResetData/ResetData.module.css';
import { AppStateContext } from '@/context/AppStateContext';
import { useContext } from 'react';
import { Interval } from '@/components/SyncIntervalSetting/Interval';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  unpushedTicketCount: number;
}

export const ResetConfirmationDialog = ({ open, onClose, onConfirm, unpushedTicketCount }: Props) => {
  const { syncInterval } = useContext(AppStateContext);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('reset', 'title')}</DialogTitle>
      <DialogContent>
        <p>{translate('reset', 'description')}</p>
        <p className={styles.info}>
          {translate('reset', 'unpushedCheckins')}: {unpushedTicketCount}
        </p>
        <small>
          {translate('reset', 'additionalInfo')} <Interval ms={syncInterval} unitDisplay="long" />;
        </small>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onConfirm}>
          {translate('reset', 'confirmButton')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClose}>
          {translate('reset', 'cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
